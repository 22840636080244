/**
 * Global color palette
 * =================================
 * - Generic colors
 * - Color scheme
 * - Common colors
 * - Links
 * - Text selection
 * - <hr>
 * - Any others..
 */

// Generic colors
$blue                         : #288ad6;
$green                        : #16c98d;
$red                          : #fa5e5b;
$yellow                       : #feef6d;
$orange                       : #fe7c08;
$pink                         : #ff708e;
$purple                       : #8e44ad;
$gray                         : #95a5a6;
$brown                        : #a5734c;

$dark                         : #000;
$bright                       : #fff;

$color-m1b-red                : #7e0408;
$color-m1b-offwhite           : #f3faf0;

// Color Scheme
$color-primary                : #a07348;
$color-secondary              : #b5b57e;
$color-highlight              : $blue;

// Common colors
$color-text                   : $color-m1b-red;
$color-bg                     : $color-m1b-offwhite;
$color-border                 : #181004;


// Links
$color-link-default           : $color-highlight;
$color-link-visited           : $color-link-default;
$color-link-hover             : darken($color-link-default, 20%);
$color-link-active            : darken($color-link-default, 20%);

// Text Selection
$color-selection              : #fff;
$color-selection-bg           : $color-primary;


// <hr> border color
$hr-color                     : lighten(#000, 70%);

// 3rd party brand colors - find more at brandcolors.net
$color-twitter                : #55acee;
$color-facebook               : #3b5998;
$color-youtube                : #cd201f;
$color-pinterest              : #bd081c;
$color-instagram              : #e4405f;
$color-whatsapp               : #25d366;
