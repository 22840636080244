/**
 * Global app/site styles
 * =================================
 */
body {  }

/**
 * Default layout container
 */
.l-container {
	width: 90%;
	max-width: #{$layout-max-width}px;
	margin: 0 auto;
}
