@charset "UTF-8";
/**
 * ██╗  ██╗██╗ ██████╗██╗  ██╗ ██████╗ ███████╗███████╗
 * ██║ ██╔╝██║██╔════╝██║ ██╔╝██╔═══██╗██╔════╝██╔════╝
 * █████╔╝ ██║██║     █████╔╝ ██║   ██║█████╗  █████╗
 * ██╔═██╗ ██║██║     ██╔═██╗ ██║   ██║██╔══╝  ██╔══╝
 * ██║  ██╗██║╚██████╗██║  ██╗╚██████╔╝██║     ██║
 * ╚═╝  ╚═╝╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝
 * By Zander Martineau & Ashley Nolan
 * http://trykickoff.com
 *
 * Project dependencies
 * =================================
 * - Mixins, helpers and functions
 * - Core variables
 * - CSS resets
 * - Typography styles
 * - Components
 * - Partials
 * - Views
 * - Global styles
 * - Helper/utility classes
 * - Print styles
 *
 * Read about our distinction between views, partials and components at
 * https://trykickoff.com/learn/css.html#views-partials--components
 *
 * ## Yeoman
 * The Yeoman generator can also be used to add new views, partials and
 * components. e.g. `yo kickoff:sass:component foo` will create a new file:
 * `scss/components/_component.scss` and add the `@import` statement in this
 * file. Install the generator by running `npm i -g yo generator-kickoff`
 *
 */
/*
 * Mixins, helpers and functions
 */
/**
 * Usage:
 *
 * ko-getVal(100, $map) - uses an integer
 * ko-getVal(mid, $map) - uses a Sass map
 * ko-getVal($foo, $map) - uses a Sass variable
 */
/**
 * Get breakpoints
 *
 * ko-bp()
 *
 * Usage:
 * ko-bp(100) - uses an integer
 * ko-bp(mid) - uses the $breakpoints Sass map
 * ko-bp($foo) - uses a Sass variable
 */
/**
 * Get type size
 *
 * ko-type()
 *
 * Usage:
 * ko-type(100) - uses an integer
 * ko-type(mid) - uses the $type Sass map
 * ko-type($foo) - uses a Sass variable
 */
/**
 * Usage:
 *
 $grid-configuration: (
   'columns': 12,
   'layouts': (
     'small': 800px,
     'medium': 1000px,
     'large': 1200px,
   ),
 );

 div {
   font-size: ko-map-deep-get($grid-configuration, 'columns');
   width: ko-map-deep-get($grid-configuration, 'layouts', 'medium');
 }
 */
/**
 * Modular scale
 * =================================
 * Usage: ko-modular-scale($font-size-base, 3, $type-scale)
 * or with the shorthand version: ko-ms(3)
 */
/**
 * Shorthand modular scale
 * Uses the $font-size-base and $type-scale framework variables so that you
 * only need to provide the scale increment
 *
 * e.g. ko-ms(5)
 */
/**
 * Multiply
 * =================
 * Multiply any value
 *
 * Usage:
 * ko-multiply(15, 2)
 * ko-multiply($baseline, 1.5)
 */
/**
 * Sass Mixins
 * - Responsive
 * - Dimensions
 * - Positioning
 * - Utility
 * - Forms
 * - Module Naming Helpers
 */
/**
 * Responsive media query mixins
 * - These are used so that we can define separate media-query content
 * - Arguments can use:
 *   - a named breakpoint in the $breakpoints map, e.g. mid
 *   - a pixel value e.g. 500px
 *   - a unitless value that is eqivalent to a pixel e.g. 500
 */
/**
 * Min-width media query:
 * - Equivalent to: @media screen and (min-width: 20em) { ... }
 * - Usage: @include ko-respond-min(mid) { ... };
 */
/**
 * Max-width media query:
 * - Equivalent to: @media screen and (max-width: 20em) { ... }
 * - Usage: @include ko-respond-max(mid) { ... };
 */
/**
 * Min-max-width media query:
 * - Equivalent to: @media screen and (min-width: 10em) and (max-width: 20em) { ... }
 * - Usage: @include ko-respond-min-max(narrow, 600) { ... };
 */
/**
 *  HiDPI mixin.
 *  @include ko-hidpi { ... };
 *  Default value set to 1.3 to target Google Nexus 7 (http://bjango.com/articles/min-device-pixel-ratio/)
 */
/**
 * Hidpi with a minimum width media query
 * @include ko-hidpi-min(bp(mid), 1.5) { ... };
 */
/**
 * Hidpi with a max width media query
 * @include ko-hidpi-max(bp(mid), 1.3) { ... };
 */
/**
 * Dimensions-based mixins
 * - REM calculation
 * - Typography mixins based on the REM mixin
 *   - font-size
 *   - line-height
 *   - font-size-ems
 */
/**
 * Typography mixins
 */
/**
 * ko-position
 * Usage:
 * @include ko-position(absolute, 10px 20px 30px 10px);
 */
/**
 * vertical-center
 * Vertically center any element. Needs support for CSS tranforms.
 * Usage:
 * @include ko-vertical-center;
 */
/**
 * Utility Mixins
 * - clearfix
 * - font-face
 * -
 * - sizing shortcuts
 *   - size
 *   - square
 * - resizable
 */
/**
 * Sizing shortcuts
 */
/**
 * bemify [modified for Kickoff]
 * https://gtihub.com/franzheidl/bemify
 * Franz Heidl 2015
 *
 * Usage: https://gist.github.com/mrmartineau/0cd2010bf265d712bafb
 */
/**
 * Core variables
 * Edit your color palette and app vars before your begin
 */
/**
 * Global color palette
 * =================================
 * - Generic colors
 * - Color scheme
 * - Common colors
 * - Links
 * - Text selection
 * - <hr>
 * - Any others..
 */
/**
 * Variables
 * =================================
 * - Typography
 * - Breakpoints
 * - Paths
 * - Layout & Grid
 * - App-specific
 */
/* stylelint-disable indentation */
/**
 * Typography
 * =================================
 *
 * Base sizes:
 * Set this in pixels (but do not add px),
 * the font-size mixin will convert to REMS
 */
/**
 * Breakpoints
 * =================================
 * There are no common breakpoints so these are just a suggestions
 * You'll need to define your own breakpoints to suit your design
 * Add your breakpoints to this Sass map
 * Used in the Kickoff grid
 */
/**
 * Grid
 * =================================
 * These variables will control the grid module.
 *
 * if your project doesnt use Kickoff’s default grid, you can delete these
 */
/**
 * Path variables
 * =================================
 */
/**
 * Z-index variables
 * =================================
 */
/**
 * App-specific variables
 * =================================
 */
/**
 * CSS resets
 */
/**
 * Kickoff reset
 * =================================
 * We use normalize.css for the bulk of our 'reset'
 */
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

/**
 * Typography styles
 * See https://trykickoff.com/demos/typography.html for more info
 */
/**
 * Typography
 * =================================
 * - Base
 * - Paragraphs
 * - Headings
 * - Type sizes
 * - Misc
 * - Utilities
 *
 * Body font size, leadings etc have been set in _variables.scss
 *
 * See http://modularscale.com for
 *
 */
html {
  font-size: 18px;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 751px) {
    html {
      font-size: 20px; } }

body {
  font-family: "Slabo 27px", serif;
  font-size: 1rem;
  line-height: 1.4;
  color: #7e0408;
  font-feature-settings: "liga", "dlig", "hist";
  font-variant-ligatures: common-ligatures discretionary-ligatures historical-ligatures; }

/**
 * Paragraphs
 */
p {
  font-family: "Slabo 27px", serif;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 28px; }

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Slabo 27px", serif;
  font-weight: bold;
  line-height: 1.1;
  text-rendering: optimizelegibility;
  margin-top: 0;
  margin-bottom: 28px; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    font-weight: normal; }

h1,
.h1,
.alpha {
  font-size: 1.95312rem;
  margin-bottom: 56px; }

h2,
.h2,
.beta,
.tel {
  font-size: 1.5625rem; }

h3,
.h3,
.gamma {
  font-size: 1.25rem; }

h4,
.h4,
.delta {
  font-size: 1rem; }

h5,
.h5,
.epsilon,
h6,
.h6,
.zeta {
  font-size: 1rem;
  margin-bottom: 0; }

* + h1,
* + .h1,
* + .alpha,
* + h2,
* + .h2,
* + .beta,
* + h3,
* + .h3,
* + .gamma,
* + h4,
* + .h4,
* + .delta {
  margin-top: 28px; }

small {
  font-size: 80%; }

/**
 * Miscellaneous
 */
strong,
b {
  font-weight: bold; }

em,
i {
  font-style: italic; }

abbr[title] {
  border-bottom: 1px dotted #ddd;
  cursor: help; }

/**
 * Blockquotes
 */
blockquote {
  padding-left: 10px;
  margin: 28px;
  border-left: 4px solid #cccccc; }
  blockquote p {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 300; }
  blockquote small {
    display: block;
    color: #b3b3b3; }
    blockquote small::before {
      content: '\2014 \00A0'; }

q::before, q::after,
blockquote::before,
blockquote::after {
  content: ""; }

cite {
  font-style: normal; }

dfn {
  font-style: italic; }

mark {
  background: #feef6d;
  padding: 2px 4px;
  border-radius: 3px; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

samp {
  font-family: Menlo, Monaco, "Courier New", monospace; }

/**
 * Utilities
 */
.text-centre,
.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

::-moz-selection {
  color: #fff;
  background: #a07348;
  text-shadow: none; }

::selection {
  color: #fff;
  background: #a07348;
  text-shadow: none; }

hr {
  margin: 28px 0;
  border: 0;
  height: 1px;
  background-color: #b3b3b3; }

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto; }

/**
 * Components
 * See https://trykickoff.com/demos/components.html for more info
 *
 * Note: place all 3rd party components at the top of the list
 */
.fluidVideo {
  position: relative;
  height: 0;
  padding-bottom: 56.25%; }
  .fluidVideo--4-3 {
    padding-bottom: 75%; }

.fluidVideo-item,
.fluidVideo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/**
 * The Kickoff Flexbox Grid
 * =================================
 * Default grid classes
 * Grid gutter options
 * Modifier Classes (column height and cell alignment)
 * Legacy fallbacks for flexbox
 * Grid span classes
 * Breakpoint stacking
 */
/* Import Grid helpers and mixins */
/**
 * Grid Variables
 * =================================
 * These are defined here so that the grid can run as a standalone module (outside of Kickoff),
 * as well as allowing these files to be generated into standard CSS.
 *
 * When importing the grid into your own project, copy the variables from this file into your own variables.scss
 * They will then override the values specified here (as long as the grid is imported below where your own variables are defined).
 */
/**
 * Kickoff grid helpers
 */
/**
 * Grid stacking
 * Stack grids based on a given breakpoint
 *
 * Usage:
 * .g--stack--narrow
 * .g--stack--wide
 */
/**
 * Gutter Calculations
 * Default: percent
 * Usage: ko-gutterCalc() or ko-gutterCalc(false)
 * When show-unit is true, returns the percentage, otherwise return a decimal
 */
/**
 * Grid columns width calculations
 * This is where the magic of working out the column widths happens
 *
 * $column-span: define the width for the number of columns required
 * $show-unit: Switch return value between percentage (default) and decimal
 * $include-gutters: if gutters should be included in the calculations. Default = false
 * $legacy-calc: if we are working out a legacy column width calculation, or not. Default = false
 */
/**
 * Column width mixin
 * Usage:
 * @include column(2);
 */
/**
 * Basic Usage:
 * =================================
	<div class="g">
		<div class="g-col g-span4 g-span6--mid"></div>
		<div class="g-col g-span8 g-span6--mid"></div>
	</div>
 */
.g {
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: row; }
  .g img {
    max-width: 100%; }

.g-col {
  display: block;
  box-sizing: border-box;
  flex: 1 0 0;
  min-width: 0; }

/**
 * Gutter calcs – applied to grid columns in our grid (direct descendants only)
 * Default: pixels (can look at changing to percentage)
 * Usage: gutterCalc() or gutterCalc(false)
 */
.g--gutter {
  margin-left: -10px;
  margin-right: -10px; }
  .g--gutter > .g-col {
    padding-left: 10px;
    padding-right: 10px; }

.g--gutter--scaled > .g-col {
  margin-left: 2%;
  margin-right: 0; }
  .g--gutter--scaled > .g-col:first-child {
    margin-left: 0; }

.g--stack > .g-col {
  flex-basis: 100%;
  max-width: 100%; }

.g--stack.g--gutter--scaled > .g-col {
  margin-left: 0; }

/**
 * .g--equalHeight – Equal Height Child Elements
 */
.g--equalHeight > .g-col {
  display: flex; }
  .g--equalHeight > .g-col > * {
    flex-basis: 100%; }

/**
 * Alignment
 * Modifier classes to move our grid elements around
 */
.g--alignTop {
  align-items: flex-start; }

.g--alignBottom {
  align-items: flex-end; }

.g--alignSelfBottom {
  align-self: flex-end; }

.g--alignRight {
  justify-content: flex-end; }

.g--alignCenter {
  justify-content: center; }

.g--alignCenter--v {
  align-items: center; }

/**
 * Centering
 * Centers an individual column, rather than the entire grid
 */
.g-col--centered {
  margin: 0 auto; }

/**
 * Shrinking Content
 * Shrink a .g-col down to only the space it needs (flexbox only)
 *
 * Effectively just changes it’s values back to the default flex properties
 */
.g-col--shrink {
  flex: 0 1 auto; }

/**
 * Add fallbacks for non-flexbox supporting browsers (for example, IE8/9)
 */
.no-flexbox .g {
  display: block !important; }
  .no-flexbox .g:after {
    content: "";
    display: table;
    clear: both; }

.no-flexbox .g-col {
  float: left;
  min-height: 1px;
  clear: none;
  box-sizing: border-box; }

.no-flexbox .g--stack .g-col {
  width: 100%; }

.no-flexbox .g--equalHeight > .g-col {
  display: block; }

/**
 * Grid Span classes (for different breakpoints)
 *
 * Applied by using .g-spanx to .g-col elements, where x is the number of columns
 */
.g-span1 {
  flex-basis: 8.33333% !important;
  flex-grow: 0;
  max-width: 8.33333% !important; }
  .g-span1.g-holdWidth {
    min-width: 83.33333px; }
    .g--gutter--scaled .g-span1.g-holdWidth {
      min-width: 63.33333px; }
  .g--gutter--scaled .g-span1 {
    flex-basis: 6.5% !important;
    max-width: 6.5%;
    margin-left: 2%; }
  .no-flexbox .g-span1 {
    width: 8.33333% !important; }

.g-offset1 {
  margin-left: 8.33333% !important; }

.g-span2 {
  flex-basis: 16.66667% !important;
  flex-grow: 0;
  max-width: 16.66667% !important; }
  .g-span2.g-holdWidth {
    min-width: 166.66667px; }
    .g--gutter--scaled .g-span2.g-holdWidth {
      min-width: 146.66667px; }
  .g--gutter--scaled .g-span2 {
    flex-basis: 15% !important;
    max-width: 15%;
    margin-left: 2%; }
  .no-flexbox .g-span2 {
    width: 16.66667% !important; }

.g-offset2 {
  margin-left: 16.66667% !important; }

.g-span3 {
  flex-basis: 25% !important;
  flex-grow: 0;
  max-width: 25% !important; }
  .g-span3.g-holdWidth {
    min-width: 250px; }
    .g--gutter--scaled .g-span3.g-holdWidth {
      min-width: 230px; }
  .g--gutter--scaled .g-span3 {
    flex-basis: 23.5% !important;
    max-width: 23.5%;
    margin-left: 2%; }
  .no-flexbox .g-span3 {
    width: 25% !important; }

.g-offset3 {
  margin-left: 25% !important; }

.g-span4 {
  flex-basis: 33.33333% !important;
  flex-grow: 0;
  max-width: 33.33333% !important; }
  .g-span4.g-holdWidth {
    min-width: 333.33333px; }
    .g--gutter--scaled .g-span4.g-holdWidth {
      min-width: 313.33333px; }
  .g--gutter--scaled .g-span4 {
    flex-basis: 32% !important;
    max-width: 32%;
    margin-left: 2%; }
  .no-flexbox .g-span4 {
    width: 33.33333% !important; }

.g-offset4 {
  margin-left: 33.33333% !important; }

.g-span5 {
  flex-basis: 41.66667% !important;
  flex-grow: 0;
  max-width: 41.66667% !important; }
  .g-span5.g-holdWidth {
    min-width: 416.66667px; }
    .g--gutter--scaled .g-span5.g-holdWidth {
      min-width: 396.66667px; }
  .g--gutter--scaled .g-span5 {
    flex-basis: 40.5% !important;
    max-width: 40.5%;
    margin-left: 2%; }
  .no-flexbox .g-span5 {
    width: 41.66667% !important; }

.g-offset5 {
  margin-left: 41.66667% !important; }

.g-span6 {
  flex-basis: 50% !important;
  flex-grow: 0;
  max-width: 50% !important; }
  .g-span6.g-holdWidth {
    min-width: 500px; }
    .g--gutter--scaled .g-span6.g-holdWidth {
      min-width: 480px; }
  .g--gutter--scaled .g-span6 {
    flex-basis: 49% !important;
    max-width: 49%;
    margin-left: 2%; }
  .no-flexbox .g-span6 {
    width: 50% !important; }

.g-offset6 {
  margin-left: 50% !important; }

.g-span7 {
  flex-basis: 58.33333% !important;
  flex-grow: 0;
  max-width: 58.33333% !important; }
  .g-span7.g-holdWidth {
    min-width: 583.33333px; }
    .g--gutter--scaled .g-span7.g-holdWidth {
      min-width: 563.33333px; }
  .g--gutter--scaled .g-span7 {
    flex-basis: 57.5% !important;
    max-width: 57.5%;
    margin-left: 2%; }
  .no-flexbox .g-span7 {
    width: 58.33333% !important; }

.g-offset7 {
  margin-left: 58.33333% !important; }

.g-span8 {
  flex-basis: 66.66667% !important;
  flex-grow: 0;
  max-width: 66.66667% !important; }
  .g-span8.g-holdWidth {
    min-width: 666.66667px; }
    .g--gutter--scaled .g-span8.g-holdWidth {
      min-width: 646.66667px; }
  .g--gutter--scaled .g-span8 {
    flex-basis: 66% !important;
    max-width: 66%;
    margin-left: 2%; }
  .no-flexbox .g-span8 {
    width: 66.66667% !important; }

.g-offset8 {
  margin-left: 66.66667% !important; }

.g-span9 {
  flex-basis: 75% !important;
  flex-grow: 0;
  max-width: 75% !important; }
  .g-span9.g-holdWidth {
    min-width: 750px; }
    .g--gutter--scaled .g-span9.g-holdWidth {
      min-width: 730px; }
  .g--gutter--scaled .g-span9 {
    flex-basis: 74.5% !important;
    max-width: 74.5%;
    margin-left: 2%; }
  .no-flexbox .g-span9 {
    width: 75% !important; }

.g-offset9 {
  margin-left: 75% !important; }

.g-span10 {
  flex-basis: 83.33333% !important;
  flex-grow: 0;
  max-width: 83.33333% !important; }
  .g-span10.g-holdWidth {
    min-width: 833.33333px; }
    .g--gutter--scaled .g-span10.g-holdWidth {
      min-width: 813.33333px; }
  .g--gutter--scaled .g-span10 {
    flex-basis: 83% !important;
    max-width: 83%;
    margin-left: 2%; }
  .no-flexbox .g-span10 {
    width: 83.33333% !important; }

.g-offset10 {
  margin-left: 83.33333% !important; }

.g-span11 {
  flex-basis: 91.66667% !important;
  flex-grow: 0;
  max-width: 91.66667% !important; }
  .g-span11.g-holdWidth {
    min-width: 916.66667px; }
    .g--gutter--scaled .g-span11.g-holdWidth {
      min-width: 896.66667px; }
  .g--gutter--scaled .g-span11 {
    flex-basis: 91.5% !important;
    max-width: 91.5%;
    margin-left: 2%; }
  .no-flexbox .g-span11 {
    width: 91.66667% !important; }

.g-offset11 {
  margin-left: 91.66667% !important; }

.g-span12 {
  flex-basis: 100% !important;
  flex-grow: 0;
  max-width: 100% !important; }
  .g-span12.g-holdWidth {
    min-width: 1000px; }
    .g--gutter--scaled .g-span12.g-holdWidth {
      min-width: 980px; }
  .g--gutter--scaled .g-span12 {
    flex-basis: 100% !important;
    max-width: 100%;
    margin-left: 0; }
  .no-flexbox .g-span12 {
    width: 100% !important; }

@media (min-width: 400px) {
  .g-span1--narrow {
    flex-basis: 8.33333% !important;
    flex-grow: 0;
    max-width: 8.33333% !important; }
    .g-span1--narrow.g-holdWidth--narrow {
      min-width: 83.33333px; }
      .g--gutter--scaled .g-span1--narrow.g-holdWidth--narrow {
        min-width: 63.33333px; }
    .g--gutter--scaled .g-span1--narrow {
      flex-basis: 6.5% !important;
      max-width: 6.5%;
      margin-left: 2%; }
    .no-flexbox .g-span1--narrow {
      width: 8.33333% !important; }
  .g-offset1--narrow {
    margin-left: 8.33333% !important; }
  .g-span2--narrow {
    flex-basis: 16.66667% !important;
    flex-grow: 0;
    max-width: 16.66667% !important; }
    .g-span2--narrow.g-holdWidth--narrow {
      min-width: 166.66667px; }
      .g--gutter--scaled .g-span2--narrow.g-holdWidth--narrow {
        min-width: 146.66667px; }
    .g--gutter--scaled .g-span2--narrow {
      flex-basis: 15% !important;
      max-width: 15%;
      margin-left: 2%; }
    .no-flexbox .g-span2--narrow {
      width: 16.66667% !important; }
  .g-offset2--narrow {
    margin-left: 16.66667% !important; }
  .g-span3--narrow {
    flex-basis: 25% !important;
    flex-grow: 0;
    max-width: 25% !important; }
    .g-span3--narrow.g-holdWidth--narrow {
      min-width: 250px; }
      .g--gutter--scaled .g-span3--narrow.g-holdWidth--narrow {
        min-width: 230px; }
    .g--gutter--scaled .g-span3--narrow {
      flex-basis: 23.5% !important;
      max-width: 23.5%;
      margin-left: 2%; }
    .no-flexbox .g-span3--narrow {
      width: 25% !important; }
  .g-offset3--narrow {
    margin-left: 25% !important; }
  .g-span4--narrow {
    flex-basis: 33.33333% !important;
    flex-grow: 0;
    max-width: 33.33333% !important; }
    .g-span4--narrow.g-holdWidth--narrow {
      min-width: 333.33333px; }
      .g--gutter--scaled .g-span4--narrow.g-holdWidth--narrow {
        min-width: 313.33333px; }
    .g--gutter--scaled .g-span4--narrow {
      flex-basis: 32% !important;
      max-width: 32%;
      margin-left: 2%; }
    .no-flexbox .g-span4--narrow {
      width: 33.33333% !important; }
  .g-offset4--narrow {
    margin-left: 33.33333% !important; }
  .g-span5--narrow {
    flex-basis: 41.66667% !important;
    flex-grow: 0;
    max-width: 41.66667% !important; }
    .g-span5--narrow.g-holdWidth--narrow {
      min-width: 416.66667px; }
      .g--gutter--scaled .g-span5--narrow.g-holdWidth--narrow {
        min-width: 396.66667px; }
    .g--gutter--scaled .g-span5--narrow {
      flex-basis: 40.5% !important;
      max-width: 40.5%;
      margin-left: 2%; }
    .no-flexbox .g-span5--narrow {
      width: 41.66667% !important; }
  .g-offset5--narrow {
    margin-left: 41.66667% !important; }
  .g-span6--narrow {
    flex-basis: 50% !important;
    flex-grow: 0;
    max-width: 50% !important; }
    .g-span6--narrow.g-holdWidth--narrow {
      min-width: 500px; }
      .g--gutter--scaled .g-span6--narrow.g-holdWidth--narrow {
        min-width: 480px; }
    .g--gutter--scaled .g-span6--narrow {
      flex-basis: 49% !important;
      max-width: 49%;
      margin-left: 2%; }
    .no-flexbox .g-span6--narrow {
      width: 50% !important; }
  .g-offset6--narrow {
    margin-left: 50% !important; }
  .g-span7--narrow {
    flex-basis: 58.33333% !important;
    flex-grow: 0;
    max-width: 58.33333% !important; }
    .g-span7--narrow.g-holdWidth--narrow {
      min-width: 583.33333px; }
      .g--gutter--scaled .g-span7--narrow.g-holdWidth--narrow {
        min-width: 563.33333px; }
    .g--gutter--scaled .g-span7--narrow {
      flex-basis: 57.5% !important;
      max-width: 57.5%;
      margin-left: 2%; }
    .no-flexbox .g-span7--narrow {
      width: 58.33333% !important; }
  .g-offset7--narrow {
    margin-left: 58.33333% !important; }
  .g-span8--narrow {
    flex-basis: 66.66667% !important;
    flex-grow: 0;
    max-width: 66.66667% !important; }
    .g-span8--narrow.g-holdWidth--narrow {
      min-width: 666.66667px; }
      .g--gutter--scaled .g-span8--narrow.g-holdWidth--narrow {
        min-width: 646.66667px; }
    .g--gutter--scaled .g-span8--narrow {
      flex-basis: 66% !important;
      max-width: 66%;
      margin-left: 2%; }
    .no-flexbox .g-span8--narrow {
      width: 66.66667% !important; }
  .g-offset8--narrow {
    margin-left: 66.66667% !important; }
  .g-span9--narrow {
    flex-basis: 75% !important;
    flex-grow: 0;
    max-width: 75% !important; }
    .g-span9--narrow.g-holdWidth--narrow {
      min-width: 750px; }
      .g--gutter--scaled .g-span9--narrow.g-holdWidth--narrow {
        min-width: 730px; }
    .g--gutter--scaled .g-span9--narrow {
      flex-basis: 74.5% !important;
      max-width: 74.5%;
      margin-left: 2%; }
    .no-flexbox .g-span9--narrow {
      width: 75% !important; }
  .g-offset9--narrow {
    margin-left: 75% !important; }
  .g-span10--narrow {
    flex-basis: 83.33333% !important;
    flex-grow: 0;
    max-width: 83.33333% !important; }
    .g-span10--narrow.g-holdWidth--narrow {
      min-width: 833.33333px; }
      .g--gutter--scaled .g-span10--narrow.g-holdWidth--narrow {
        min-width: 813.33333px; }
    .g--gutter--scaled .g-span10--narrow {
      flex-basis: 83% !important;
      max-width: 83%;
      margin-left: 2%; }
    .no-flexbox .g-span10--narrow {
      width: 83.33333% !important; }
  .g-offset10--narrow {
    margin-left: 83.33333% !important; }
  .g-span11--narrow {
    flex-basis: 91.66667% !important;
    flex-grow: 0;
    max-width: 91.66667% !important; }
    .g-span11--narrow.g-holdWidth--narrow {
      min-width: 916.66667px; }
      .g--gutter--scaled .g-span11--narrow.g-holdWidth--narrow {
        min-width: 896.66667px; }
    .g--gutter--scaled .g-span11--narrow {
      flex-basis: 91.5% !important;
      max-width: 91.5%;
      margin-left: 2%; }
    .no-flexbox .g-span11--narrow {
      width: 91.66667% !important; }
  .g-offset11--narrow {
    margin-left: 91.66667% !important; }
  .g-span12--narrow {
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important; }
    .g-span12--narrow.g-holdWidth--narrow {
      min-width: 1000px; }
      .g--gutter--scaled .g-span12--narrow.g-holdWidth--narrow {
        min-width: 980px; }
    .g--gutter--scaled .g-span12--narrow {
      flex-basis: 100% !important;
      max-width: 100%;
      margin-left: 0; }
    .no-flexbox .g-span12--narrow {
      width: 100% !important; } }

@media (min-width: 750px) {
  .g-span1--mid {
    flex-basis: 8.33333% !important;
    flex-grow: 0;
    max-width: 8.33333% !important; }
    .g-span1--mid.g-holdWidth--mid {
      min-width: 83.33333px; }
      .g--gutter--scaled .g-span1--mid.g-holdWidth--mid {
        min-width: 63.33333px; }
    .g--gutter--scaled .g-span1--mid {
      flex-basis: 6.5% !important;
      max-width: 6.5%;
      margin-left: 2%; }
    .no-flexbox .g-span1--mid {
      width: 8.33333% !important; }
  .g-offset1--mid {
    margin-left: 8.33333% !important; }
  .g-span2--mid {
    flex-basis: 16.66667% !important;
    flex-grow: 0;
    max-width: 16.66667% !important; }
    .g-span2--mid.g-holdWidth--mid {
      min-width: 166.66667px; }
      .g--gutter--scaled .g-span2--mid.g-holdWidth--mid {
        min-width: 146.66667px; }
    .g--gutter--scaled .g-span2--mid {
      flex-basis: 15% !important;
      max-width: 15%;
      margin-left: 2%; }
    .no-flexbox .g-span2--mid {
      width: 16.66667% !important; }
  .g-offset2--mid {
    margin-left: 16.66667% !important; }
  .g-span3--mid {
    flex-basis: 25% !important;
    flex-grow: 0;
    max-width: 25% !important; }
    .g-span3--mid.g-holdWidth--mid {
      min-width: 250px; }
      .g--gutter--scaled .g-span3--mid.g-holdWidth--mid {
        min-width: 230px; }
    .g--gutter--scaled .g-span3--mid {
      flex-basis: 23.5% !important;
      max-width: 23.5%;
      margin-left: 2%; }
    .no-flexbox .g-span3--mid {
      width: 25% !important; }
  .g-offset3--mid {
    margin-left: 25% !important; }
  .g-span4--mid {
    flex-basis: 33.33333% !important;
    flex-grow: 0;
    max-width: 33.33333% !important; }
    .g-span4--mid.g-holdWidth--mid {
      min-width: 333.33333px; }
      .g--gutter--scaled .g-span4--mid.g-holdWidth--mid {
        min-width: 313.33333px; }
    .g--gutter--scaled .g-span4--mid {
      flex-basis: 32% !important;
      max-width: 32%;
      margin-left: 2%; }
    .no-flexbox .g-span4--mid {
      width: 33.33333% !important; }
  .g-offset4--mid {
    margin-left: 33.33333% !important; }
  .g-span5--mid {
    flex-basis: 41.66667% !important;
    flex-grow: 0;
    max-width: 41.66667% !important; }
    .g-span5--mid.g-holdWidth--mid {
      min-width: 416.66667px; }
      .g--gutter--scaled .g-span5--mid.g-holdWidth--mid {
        min-width: 396.66667px; }
    .g--gutter--scaled .g-span5--mid {
      flex-basis: 40.5% !important;
      max-width: 40.5%;
      margin-left: 2%; }
    .no-flexbox .g-span5--mid {
      width: 41.66667% !important; }
  .g-offset5--mid {
    margin-left: 41.66667% !important; }
  .g-span6--mid {
    flex-basis: 50% !important;
    flex-grow: 0;
    max-width: 50% !important; }
    .g-span6--mid.g-holdWidth--mid {
      min-width: 500px; }
      .g--gutter--scaled .g-span6--mid.g-holdWidth--mid {
        min-width: 480px; }
    .g--gutter--scaled .g-span6--mid {
      flex-basis: 49% !important;
      max-width: 49%;
      margin-left: 2%; }
    .no-flexbox .g-span6--mid {
      width: 50% !important; }
  .g-offset6--mid {
    margin-left: 50% !important; }
  .g-span7--mid {
    flex-basis: 58.33333% !important;
    flex-grow: 0;
    max-width: 58.33333% !important; }
    .g-span7--mid.g-holdWidth--mid {
      min-width: 583.33333px; }
      .g--gutter--scaled .g-span7--mid.g-holdWidth--mid {
        min-width: 563.33333px; }
    .g--gutter--scaled .g-span7--mid {
      flex-basis: 57.5% !important;
      max-width: 57.5%;
      margin-left: 2%; }
    .no-flexbox .g-span7--mid {
      width: 58.33333% !important; }
  .g-offset7--mid {
    margin-left: 58.33333% !important; }
  .g-span8--mid {
    flex-basis: 66.66667% !important;
    flex-grow: 0;
    max-width: 66.66667% !important; }
    .g-span8--mid.g-holdWidth--mid {
      min-width: 666.66667px; }
      .g--gutter--scaled .g-span8--mid.g-holdWidth--mid {
        min-width: 646.66667px; }
    .g--gutter--scaled .g-span8--mid {
      flex-basis: 66% !important;
      max-width: 66%;
      margin-left: 2%; }
    .no-flexbox .g-span8--mid {
      width: 66.66667% !important; }
  .g-offset8--mid {
    margin-left: 66.66667% !important; }
  .g-span9--mid {
    flex-basis: 75% !important;
    flex-grow: 0;
    max-width: 75% !important; }
    .g-span9--mid.g-holdWidth--mid {
      min-width: 750px; }
      .g--gutter--scaled .g-span9--mid.g-holdWidth--mid {
        min-width: 730px; }
    .g--gutter--scaled .g-span9--mid {
      flex-basis: 74.5% !important;
      max-width: 74.5%;
      margin-left: 2%; }
    .no-flexbox .g-span9--mid {
      width: 75% !important; }
  .g-offset9--mid {
    margin-left: 75% !important; }
  .g-span10--mid {
    flex-basis: 83.33333% !important;
    flex-grow: 0;
    max-width: 83.33333% !important; }
    .g-span10--mid.g-holdWidth--mid {
      min-width: 833.33333px; }
      .g--gutter--scaled .g-span10--mid.g-holdWidth--mid {
        min-width: 813.33333px; }
    .g--gutter--scaled .g-span10--mid {
      flex-basis: 83% !important;
      max-width: 83%;
      margin-left: 2%; }
    .no-flexbox .g-span10--mid {
      width: 83.33333% !important; }
  .g-offset10--mid {
    margin-left: 83.33333% !important; }
  .g-span11--mid {
    flex-basis: 91.66667% !important;
    flex-grow: 0;
    max-width: 91.66667% !important; }
    .g-span11--mid.g-holdWidth--mid {
      min-width: 916.66667px; }
      .g--gutter--scaled .g-span11--mid.g-holdWidth--mid {
        min-width: 896.66667px; }
    .g--gutter--scaled .g-span11--mid {
      flex-basis: 91.5% !important;
      max-width: 91.5%;
      margin-left: 2%; }
    .no-flexbox .g-span11--mid {
      width: 91.66667% !important; }
  .g-offset11--mid {
    margin-left: 91.66667% !important; }
  .g-span12--mid {
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important; }
    .g-span12--mid.g-holdWidth--mid {
      min-width: 1000px; }
      .g--gutter--scaled .g-span12--mid.g-holdWidth--mid {
        min-width: 980px; }
    .g--gutter--scaled .g-span12--mid {
      flex-basis: 100% !important;
      max-width: 100%;
      margin-left: 0; }
    .no-flexbox .g-span12--mid {
      width: 100% !important; } }

@media (min-width: 1000px) {
  .g-span1--wide {
    flex-basis: 8.33333% !important;
    flex-grow: 0;
    max-width: 8.33333% !important; }
    .g-span1--wide.g-holdWidth--wide {
      min-width: 83.33333px; }
      .g--gutter--scaled .g-span1--wide.g-holdWidth--wide {
        min-width: 63.33333px; }
    .g--gutter--scaled .g-span1--wide {
      flex-basis: 6.5% !important;
      max-width: 6.5%;
      margin-left: 2%; }
    .no-flexbox .g-span1--wide {
      width: 8.33333% !important; }
  .g-offset1--wide {
    margin-left: 8.33333% !important; }
  .g-span2--wide {
    flex-basis: 16.66667% !important;
    flex-grow: 0;
    max-width: 16.66667% !important; }
    .g-span2--wide.g-holdWidth--wide {
      min-width: 166.66667px; }
      .g--gutter--scaled .g-span2--wide.g-holdWidth--wide {
        min-width: 146.66667px; }
    .g--gutter--scaled .g-span2--wide {
      flex-basis: 15% !important;
      max-width: 15%;
      margin-left: 2%; }
    .no-flexbox .g-span2--wide {
      width: 16.66667% !important; }
  .g-offset2--wide {
    margin-left: 16.66667% !important; }
  .g-span3--wide {
    flex-basis: 25% !important;
    flex-grow: 0;
    max-width: 25% !important; }
    .g-span3--wide.g-holdWidth--wide {
      min-width: 250px; }
      .g--gutter--scaled .g-span3--wide.g-holdWidth--wide {
        min-width: 230px; }
    .g--gutter--scaled .g-span3--wide {
      flex-basis: 23.5% !important;
      max-width: 23.5%;
      margin-left: 2%; }
    .no-flexbox .g-span3--wide {
      width: 25% !important; }
  .g-offset3--wide {
    margin-left: 25% !important; }
  .g-span4--wide {
    flex-basis: 33.33333% !important;
    flex-grow: 0;
    max-width: 33.33333% !important; }
    .g-span4--wide.g-holdWidth--wide {
      min-width: 333.33333px; }
      .g--gutter--scaled .g-span4--wide.g-holdWidth--wide {
        min-width: 313.33333px; }
    .g--gutter--scaled .g-span4--wide {
      flex-basis: 32% !important;
      max-width: 32%;
      margin-left: 2%; }
    .no-flexbox .g-span4--wide {
      width: 33.33333% !important; }
  .g-offset4--wide {
    margin-left: 33.33333% !important; }
  .g-span5--wide {
    flex-basis: 41.66667% !important;
    flex-grow: 0;
    max-width: 41.66667% !important; }
    .g-span5--wide.g-holdWidth--wide {
      min-width: 416.66667px; }
      .g--gutter--scaled .g-span5--wide.g-holdWidth--wide {
        min-width: 396.66667px; }
    .g--gutter--scaled .g-span5--wide {
      flex-basis: 40.5% !important;
      max-width: 40.5%;
      margin-left: 2%; }
    .no-flexbox .g-span5--wide {
      width: 41.66667% !important; }
  .g-offset5--wide {
    margin-left: 41.66667% !important; }
  .g-span6--wide {
    flex-basis: 50% !important;
    flex-grow: 0;
    max-width: 50% !important; }
    .g-span6--wide.g-holdWidth--wide {
      min-width: 500px; }
      .g--gutter--scaled .g-span6--wide.g-holdWidth--wide {
        min-width: 480px; }
    .g--gutter--scaled .g-span6--wide {
      flex-basis: 49% !important;
      max-width: 49%;
      margin-left: 2%; }
    .no-flexbox .g-span6--wide {
      width: 50% !important; }
  .g-offset6--wide {
    margin-left: 50% !important; }
  .g-span7--wide {
    flex-basis: 58.33333% !important;
    flex-grow: 0;
    max-width: 58.33333% !important; }
    .g-span7--wide.g-holdWidth--wide {
      min-width: 583.33333px; }
      .g--gutter--scaled .g-span7--wide.g-holdWidth--wide {
        min-width: 563.33333px; }
    .g--gutter--scaled .g-span7--wide {
      flex-basis: 57.5% !important;
      max-width: 57.5%;
      margin-left: 2%; }
    .no-flexbox .g-span7--wide {
      width: 58.33333% !important; }
  .g-offset7--wide {
    margin-left: 58.33333% !important; }
  .g-span8--wide {
    flex-basis: 66.66667% !important;
    flex-grow: 0;
    max-width: 66.66667% !important; }
    .g-span8--wide.g-holdWidth--wide {
      min-width: 666.66667px; }
      .g--gutter--scaled .g-span8--wide.g-holdWidth--wide {
        min-width: 646.66667px; }
    .g--gutter--scaled .g-span8--wide {
      flex-basis: 66% !important;
      max-width: 66%;
      margin-left: 2%; }
    .no-flexbox .g-span8--wide {
      width: 66.66667% !important; }
  .g-offset8--wide {
    margin-left: 66.66667% !important; }
  .g-span9--wide {
    flex-basis: 75% !important;
    flex-grow: 0;
    max-width: 75% !important; }
    .g-span9--wide.g-holdWidth--wide {
      min-width: 750px; }
      .g--gutter--scaled .g-span9--wide.g-holdWidth--wide {
        min-width: 730px; }
    .g--gutter--scaled .g-span9--wide {
      flex-basis: 74.5% !important;
      max-width: 74.5%;
      margin-left: 2%; }
    .no-flexbox .g-span9--wide {
      width: 75% !important; }
  .g-offset9--wide {
    margin-left: 75% !important; }
  .g-span10--wide {
    flex-basis: 83.33333% !important;
    flex-grow: 0;
    max-width: 83.33333% !important; }
    .g-span10--wide.g-holdWidth--wide {
      min-width: 833.33333px; }
      .g--gutter--scaled .g-span10--wide.g-holdWidth--wide {
        min-width: 813.33333px; }
    .g--gutter--scaled .g-span10--wide {
      flex-basis: 83% !important;
      max-width: 83%;
      margin-left: 2%; }
    .no-flexbox .g-span10--wide {
      width: 83.33333% !important; }
  .g-offset10--wide {
    margin-left: 83.33333% !important; }
  .g-span11--wide {
    flex-basis: 91.66667% !important;
    flex-grow: 0;
    max-width: 91.66667% !important; }
    .g-span11--wide.g-holdWidth--wide {
      min-width: 916.66667px; }
      .g--gutter--scaled .g-span11--wide.g-holdWidth--wide {
        min-width: 896.66667px; }
    .g--gutter--scaled .g-span11--wide {
      flex-basis: 91.5% !important;
      max-width: 91.5%;
      margin-left: 2%; }
    .no-flexbox .g-span11--wide {
      width: 91.66667% !important; }
  .g-offset11--wide {
    margin-left: 91.66667% !important; }
  .g-span12--wide {
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important; }
    .g-span12--wide.g-holdWidth--wide {
      min-width: 1000px; }
      .g--gutter--scaled .g-span12--wide.g-holdWidth--wide {
        min-width: 980px; }
    .g--gutter--scaled .g-span12--wide {
      flex-basis: 100% !important;
      max-width: 100%;
      margin-left: 0; }
    .no-flexbox .g-span12--wide {
      width: 100% !important; } }

@media (min-width: 1250px) {
  .g-span1--huge {
    flex-basis: 8.33333% !important;
    flex-grow: 0;
    max-width: 8.33333% !important; }
    .g-span1--huge.g-holdWidth--huge {
      min-width: 83.33333px; }
      .g--gutter--scaled .g-span1--huge.g-holdWidth--huge {
        min-width: 63.33333px; }
    .g--gutter--scaled .g-span1--huge {
      flex-basis: 6.5% !important;
      max-width: 6.5%;
      margin-left: 2%; }
    .no-flexbox .g-span1--huge {
      width: 8.33333% !important; }
  .g-offset1--huge {
    margin-left: 8.33333% !important; }
  .g-span2--huge {
    flex-basis: 16.66667% !important;
    flex-grow: 0;
    max-width: 16.66667% !important; }
    .g-span2--huge.g-holdWidth--huge {
      min-width: 166.66667px; }
      .g--gutter--scaled .g-span2--huge.g-holdWidth--huge {
        min-width: 146.66667px; }
    .g--gutter--scaled .g-span2--huge {
      flex-basis: 15% !important;
      max-width: 15%;
      margin-left: 2%; }
    .no-flexbox .g-span2--huge {
      width: 16.66667% !important; }
  .g-offset2--huge {
    margin-left: 16.66667% !important; }
  .g-span3--huge {
    flex-basis: 25% !important;
    flex-grow: 0;
    max-width: 25% !important; }
    .g-span3--huge.g-holdWidth--huge {
      min-width: 250px; }
      .g--gutter--scaled .g-span3--huge.g-holdWidth--huge {
        min-width: 230px; }
    .g--gutter--scaled .g-span3--huge {
      flex-basis: 23.5% !important;
      max-width: 23.5%;
      margin-left: 2%; }
    .no-flexbox .g-span3--huge {
      width: 25% !important; }
  .g-offset3--huge {
    margin-left: 25% !important; }
  .g-span4--huge {
    flex-basis: 33.33333% !important;
    flex-grow: 0;
    max-width: 33.33333% !important; }
    .g-span4--huge.g-holdWidth--huge {
      min-width: 333.33333px; }
      .g--gutter--scaled .g-span4--huge.g-holdWidth--huge {
        min-width: 313.33333px; }
    .g--gutter--scaled .g-span4--huge {
      flex-basis: 32% !important;
      max-width: 32%;
      margin-left: 2%; }
    .no-flexbox .g-span4--huge {
      width: 33.33333% !important; }
  .g-offset4--huge {
    margin-left: 33.33333% !important; }
  .g-span5--huge {
    flex-basis: 41.66667% !important;
    flex-grow: 0;
    max-width: 41.66667% !important; }
    .g-span5--huge.g-holdWidth--huge {
      min-width: 416.66667px; }
      .g--gutter--scaled .g-span5--huge.g-holdWidth--huge {
        min-width: 396.66667px; }
    .g--gutter--scaled .g-span5--huge {
      flex-basis: 40.5% !important;
      max-width: 40.5%;
      margin-left: 2%; }
    .no-flexbox .g-span5--huge {
      width: 41.66667% !important; }
  .g-offset5--huge {
    margin-left: 41.66667% !important; }
  .g-span6--huge {
    flex-basis: 50% !important;
    flex-grow: 0;
    max-width: 50% !important; }
    .g-span6--huge.g-holdWidth--huge {
      min-width: 500px; }
      .g--gutter--scaled .g-span6--huge.g-holdWidth--huge {
        min-width: 480px; }
    .g--gutter--scaled .g-span6--huge {
      flex-basis: 49% !important;
      max-width: 49%;
      margin-left: 2%; }
    .no-flexbox .g-span6--huge {
      width: 50% !important; }
  .g-offset6--huge {
    margin-left: 50% !important; }
  .g-span7--huge {
    flex-basis: 58.33333% !important;
    flex-grow: 0;
    max-width: 58.33333% !important; }
    .g-span7--huge.g-holdWidth--huge {
      min-width: 583.33333px; }
      .g--gutter--scaled .g-span7--huge.g-holdWidth--huge {
        min-width: 563.33333px; }
    .g--gutter--scaled .g-span7--huge {
      flex-basis: 57.5% !important;
      max-width: 57.5%;
      margin-left: 2%; }
    .no-flexbox .g-span7--huge {
      width: 58.33333% !important; }
  .g-offset7--huge {
    margin-left: 58.33333% !important; }
  .g-span8--huge {
    flex-basis: 66.66667% !important;
    flex-grow: 0;
    max-width: 66.66667% !important; }
    .g-span8--huge.g-holdWidth--huge {
      min-width: 666.66667px; }
      .g--gutter--scaled .g-span8--huge.g-holdWidth--huge {
        min-width: 646.66667px; }
    .g--gutter--scaled .g-span8--huge {
      flex-basis: 66% !important;
      max-width: 66%;
      margin-left: 2%; }
    .no-flexbox .g-span8--huge {
      width: 66.66667% !important; }
  .g-offset8--huge {
    margin-left: 66.66667% !important; }
  .g-span9--huge {
    flex-basis: 75% !important;
    flex-grow: 0;
    max-width: 75% !important; }
    .g-span9--huge.g-holdWidth--huge {
      min-width: 750px; }
      .g--gutter--scaled .g-span9--huge.g-holdWidth--huge {
        min-width: 730px; }
    .g--gutter--scaled .g-span9--huge {
      flex-basis: 74.5% !important;
      max-width: 74.5%;
      margin-left: 2%; }
    .no-flexbox .g-span9--huge {
      width: 75% !important; }
  .g-offset9--huge {
    margin-left: 75% !important; }
  .g-span10--huge {
    flex-basis: 83.33333% !important;
    flex-grow: 0;
    max-width: 83.33333% !important; }
    .g-span10--huge.g-holdWidth--huge {
      min-width: 833.33333px; }
      .g--gutter--scaled .g-span10--huge.g-holdWidth--huge {
        min-width: 813.33333px; }
    .g--gutter--scaled .g-span10--huge {
      flex-basis: 83% !important;
      max-width: 83%;
      margin-left: 2%; }
    .no-flexbox .g-span10--huge {
      width: 83.33333% !important; }
  .g-offset10--huge {
    margin-left: 83.33333% !important; }
  .g-span11--huge {
    flex-basis: 91.66667% !important;
    flex-grow: 0;
    max-width: 91.66667% !important; }
    .g-span11--huge.g-holdWidth--huge {
      min-width: 916.66667px; }
      .g--gutter--scaled .g-span11--huge.g-holdWidth--huge {
        min-width: 896.66667px; }
    .g--gutter--scaled .g-span11--huge {
      flex-basis: 91.5% !important;
      max-width: 91.5%;
      margin-left: 2%; }
    .no-flexbox .g-span11--huge {
      width: 91.66667% !important; }
  .g-offset11--huge {
    margin-left: 91.66667% !important; }
  .g-span12--huge {
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important; }
    .g-span12--huge.g-holdWidth--huge {
      min-width: 1000px; }
      .g--gutter--scaled .g-span12--huge.g-holdWidth--huge {
        min-width: 980px; }
    .g--gutter--scaled .g-span12--huge {
      flex-basis: 100% !important;
      max-width: 100%;
      margin-left: 0; }
    .no-flexbox .g-span12--huge {
      width: 100% !important; } }

@media (max-width: 399px) {
  .g--stack--narrow {
    flex-flow: column nowrap; }
    .g--stack--narrow > .g-col {
      flex-basis: auto !important;
      max-width: 100% !important;
      margin-left: 0; }
      .no-flexbox .g--stack--narrow > .g-col {
        width: 100% !important; } }

@media (max-width: 749px) {
  .g--stack--mid {
    flex-flow: column nowrap; }
    .g--stack--mid > .g-col {
      flex-basis: auto !important;
      max-width: 100% !important;
      margin-left: 0; }
      .no-flexbox .g--stack--mid > .g-col {
        width: 100% !important; } }

@media (max-width: 999px) {
  .g--stack--wide {
    flex-flow: column nowrap; }
    .g--stack--wide > .g-col {
      flex-basis: auto !important;
      max-width: 100% !important;
      margin-left: 0; }
      .no-flexbox .g--stack--wide > .g-col {
        width: 100% !important; } }

@media (max-width: 1249px) {
  .g--stack--huge {
    flex-flow: column nowrap; }
    .g--stack--huge > .g-col {
      flex-basis: auto !important;
      max-width: 100% !important;
      margin-left: 0; }
      .no-flexbox .g--stack--huge > .g-col {
        width: 100% !important; } }

/**
 * Forms
 * =================================
 * Kickoff's forms has support for themes. See below for info about them.
 *
 * The styles here are base styles that apply to both themes,
 * they are more of a reset before you make stylistic amends to form fields
 *
 */
/**
 * Form themes
 * This file contains the base form styles and each theme file contains
 * overrides and additions.
 *
 * Choose from:
 * - `standard`: the original Kickoff form theme
 * - `material`: basic version of Google's Material design forms
 *
 * Usage: (choose from)
 * @import "form-theme-standard";
 * @import "form-theme-material";
 */
/**
 * Kickoff forms: Standard theme
 * ================================= *
 * Demo: http://trykickoff.com/demos/forms.html#layout-example-labels-above
 * Code: https://gist.github.com/mrmartineau/6712577#file-labels-above-inputs-html
 * Usage:
  	<div class="form-controlGroup">
  		<label for="name" class="form-label">Name</label>
  		<div class="form-controlGroup-inputWrapper">
  			<input type="text" id="name" placeholder="Zander Martineau" class="form-input"/>
  		</div>
  	</div>
 *
 * Contents:
 * - Theme variables
 * - Theme styles
 */
/**
 * Generic form styles
 * =================================
 * Demo: http://trykickoff.com/demos/forms.html#layout-example-labels-above
 * Code: https://gist.github.com/mrmartineau/6712577#file-labels-above-inputs-html
 * Usage:
  	<div class="form-controlGroup">
  		<label for="name" class="form-label">Name</label>
  		<div class="form-controlGroup-inputWrapper">
  			<input type="text" id="name" placeholder="Zander Martineau" class="form-input"/>
  		</div>
  	</div>
 */
.form {
  margin: 0 0; }

.form-fieldset {
  padding: 0;
  margin: 0;
  border: 0; }

.form-legend {
  padding: 0;
  margin-bottom: 28px;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #7e0408;
  border: 0;
  border-bottom: 1px solid #eee;
  white-space: normal; }
  .form-legend small {
    font-size: 0.8rem;
    color: #b3b3b3; }

/**
 * Form inputs and groups
 */
.form-controlGroup {
  margin: 0; }

.form-legend + .form-controlGroup {
  margin-top: 28px; }

.form-label {
  color: #7e0408; }
  .form-label small {
    font-weight: normal;
    color: #f8232a; }

.form-input {
  height: 2.5em;
  padding: 0.1em 1em;
  margin: 0.2em;
  font-size: 1rem;
  font-family: "Slabo 27px", serif;
  line-height: 1.4;
  color: #7e0408;
  font-weight: normal;
  background-color: #b5b57e;
  border: 1px solid #181004;
  border-radius: 20px;
  background-clip: padding-box;
  transition: border 200ms ease-in-out; }
  .form-input:focus {
    border-color: #7e0408; }
  .form-input[type="image"], .form-input[type="checkbox"], .form-input[type="radio"], .form-input[type="range"] {
    margin: 3px 0; }
  .form-input[type="file"] {
    background-color: #b5b57e; }
  .form-input[type="color"] {
    width: 40px;
    height: 40px;
    overflow: hidden;
    padding: 2px; }
  .form-input[disabled], .form-input[readonly] {
    cursor: not-allowed; }

.form-input--select {
  background-color: #b5b57e; }

.form-input-file {
  font-size: 1rem; }

/**
 * Form actions
 */
.form-actions {
  padding: 28px 20px;
  margin-top: 28px;
  margin-bottom: 28px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd; }
  .form-actions .btn {
    margin-right: 10px; }
    .form-actions .btn:last-child {
      margin-right: 0; }

/**
 * Form validation messages
 */
.form-controlGroup .form-message {
  padding-top: 0.5em;
  padding-left: 0.5em; }

.form-actions .form-message {
  padding: 0.5em; }

/**
 * Form field feedback states
 */
.has-warning {
  color: #f4aa47; }

.form-controlGroup.has-warning .form-label {
  color: #f4aa47; }

.form-controlGroup.has-warning .form-input {
  color: #f4aa47;
  border-color: #f4aa47; }
  .form-controlGroup.has-warning .form-input:focus {
    border-color: #f19417; }

.form-controlGroup.has-warning .form-message {
  display: block;
  color: #f4aa47; }

.has-error {
  color: #B94A4D; }

.form-controlGroup.has-error .form-label {
  color: #B94A4D; }

.form-controlGroup.has-error .form-input {
  color: #B94A4D;
  border-color: #B94A4D; }
  .form-controlGroup.has-error .form-input:focus {
    border-color: #963a3c; }

.form-controlGroup.has-error .form-message {
  display: block;
  color: #B94A4D; }

.has-success {
  color: #16c98d; }

.form-controlGroup.has-success .form-label {
  color: #16c98d; }

.form-controlGroup.has-success .form-input {
  color: #16c98d;
  border-color: #16c98d; }
  .form-controlGroup.has-success .form-input:focus {
    border-color: #119b6d; }

.form-controlGroup.has-success .form-message {
  display: block;
  color: #16c98d; }

.has-info {
  color: #288ad6; }

.form-controlGroup.has-info .form-label {
  color: #288ad6; }

.form-controlGroup.has-info .form-input {
  color: #288ad6;
  border-color: #288ad6; }
  .form-controlGroup.has-info .form-input:focus {
    border-color: #206eab; }

.form-controlGroup.has-info .form-message {
  display: block;
  color: #288ad6; }

/**
 * Placeholder text
 * =================================
 * Gets special styles; can't be bundled together though for some reason
 */
:-ms-input-placeholder {
  color: #b3b3b3; }

:-moz-placeholder {
  color: #b3b3b3; }

::-webkit-input-placeholder {
  color: #b3b3b3; }

@media (min-width: 751px) {
  /**
	 * Horizontal-specific styles
	 * =================================
	 * add .form--horizontal to the <form> element
	 * Demo: http://trykickoff.com/demos/forms.html#layout-example-labels-inline
	 * Code: https://gist.github.com/mrmartineau/6712577#file-labels-inline-html
	 */
  .form--horizontal .form-controlGroup {
    display: flex; }
    .form--horizontal .form-controlGroup > .form-controlGroup-inputWrapper {
      width: 100%; }
  .form--horizontal .form-label {
    width: 140px;
    margin-right: 28px;
    padding-top: 0.6em; }
  .form--horizontal .form-actions {
    padding-left: 168px; } }

/**
 * Custom controls
 * - Radios/checkboxes
 * - Select element
 * - File upload
 */
/**
 * Custom Checkboxes and radios
 * =================================
 * IE9+
 *
 * --------------------------------
 *
 * Checkbox button example HTML
 *
 *	<label class="control control--custom" for="checkbox1">
		<input class="control--custom-input" type="checkbox" id="checkbox1" name="myCheckbox" value="A value"/>
			<span class="control-indicator control-indicator--checkbox"></span>
			My Checkbox Label Text
		</label>
 *
 * -------------------------------
 *
 * Radio button example HTML
 *
 *	<label class="control control--custom" for="radio1">
 *		<input class="control--custom-input" type="radio" id="radio1" name="myRadioBtn" value="A value"/>
 *		<span class="control-indicator control-indicator--radio"></span>
 *		My Radio Button Label Text
 * 	</label>
 *
 * ================================= */
.control--custom {
  position: relative;
  display: block;
  cursor: pointer;
  user-select: none;
  padding-left: 2.3em;
  height: 1.3em;
  line-height: 1.3em !important; }

.control--custom--inline {
  display: inline-block;
  padding-right: 2em; }

.control--custom-input {
  position: absolute;
  opacity: 0;
  z-index: -1; }

.control-indicator {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 1.3em;
  height: 1.3em;
  transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1);
  border: 1px solid #ccc;
  background-color: #ccc;
  vertical-align: middle;
  margin-top: 0;
  box-shadow: 0 0 0 2px transparent, 0 0 0 0 transparent; }

.control--custom-input:focus ~ .control-indicator {
  box-shadow: 0 0 0 2px #f3faf0, 0 0 0 3px #a07348; }

.control-indicator--checkbox,
.control-indicator--tickbox {
  border-radius: .2em; }
  .control-indicator--checkbox:before, .control-indicator--checkbox:after,
  .control-indicator--tickbox:before,
  .control-indicator--tickbox:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    display: block;
    opacity: 0;
    transition: all 250ms ease-in-out; }

.control-indicator--checkbox:before, .control-indicator--checkbox:after {
  top: 50%;
  width: 90%;
  height: 0.1em;
  background-color: #f3faf0; }

.control-indicator--checkbox:before {
  transform: translate(-50%, -50%) rotate(45deg) scale(0); }

.control-indicator--checkbox:after {
  transform: translate(-50%, -50%) rotate(-45deg) scale(0); }

.control-indicator--tickbox:before {
  top: 0;
  transform: translateX(-50%) rotate(45deg) scale(0);
  border: 0.1em solid #f3faf0;
  background-color: transparent;
  width: 40%;
  height: 80%;
  border-top: 0;
  border-left: 0; }

.control-indicator--tickbox:after {
  display: none; }

.control--custom-input:checked ~ .control-indicator--checkbox,
.control--custom-input:checked ~ .control-indicator--tickbox {
  background-color: #a07348;
  border: 0.1em solid #a07348; }
  .control--custom-input:checked ~ .control-indicator--checkbox:before, .control--custom-input:checked ~ .control-indicator--checkbox:after,
  .control--custom-input:checked ~ .control-indicator--tickbox:before,
  .control--custom-input:checked ~ .control-indicator--tickbox:after {
    opacity: 1; }

.control--custom-input:checked ~ .control-indicator--tickbox:before, .control--custom-input:checked ~ .control-indicator--tickbox:after {
  transform: translateX(-50%) rotate(45deg) scale(1); }

.control--custom-input:checked ~ .control-indicator--checkbox:before {
  transform: translate(-50%, -50%) rotate(45deg) scale(1); }

.control--custom-input:checked ~ .control-indicator--checkbox:after {
  transform: translate(-50%, -50%) rotate(-45deg) scale(1); }

.control-indicator--radio {
  border-radius: 50%; }

.control--custom-input:checked ~ .control-indicator--radio {
  background-color: #f3faf0;
  border: 0.3em solid #a07348; }

/**
 * Custom select element
 * =================================
 * Firefox 39+, IE10+
 *
 *	<div class="form-controlGroup">
			<label for="cs-choice" class="form-label">Custom select</label>
			<div class="form-controlGroup-inputWrapper form-controlGroup-inputWrapper--select">
				<select id="cs-choice" name="cs-choice" class="form-input form-input--select">
					<option value="--">Select title</option>
					<option value="Mr">Mr</option>
					<option value="Mrs">Mrs</option>
				</select>
			</div>
	</div>
 */
.form-controlGroup-inputWrapper--select {
  position: relative;
  display: block;
  padding: 0;
  /* Custom arrow sits on top of the select - could be an image, SVG, icon font, etc. or the arrow could just baked into the bg image on the select */ }
  .form-controlGroup-inputWrapper--select:after {
    color: #a07348;
    content: "\25BC";
    font-size: .7em;
    padding: 0;
    position: absolute;
    right: 1.2em;
    top: 1.2em;
    z-index: 2;
    /* These hacks make the select behind the arrow clickable in some browsers */
    -ms-pointer-events: none;
    pointer-events: none;
    background: url(#); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .form-controlGroup-inputWrapper--select:after {
      display: none; } }
  .form-controlGroup-inputWrapper--select .form-input--select {
    appearance: none; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .form-controlGroup-inputWrapper--select .form-input--select:focus::-ms-value {
        background: transparent;
        color: #222; } }

option {
  font-weight: normal; }

/**
 * Custom file input
 * =================================
 * <div class="form-controlGroup">
		<label class="form-label" for="file">File upload</label>

		<div class="form-controlGroup-inputWrapper">
			<label class="form-input form-input--file">
				<span class="form-input--file-text">Choose file..</span>
				<span class="form-input--file-button">Browse</span>
				<input class="form-input--file-input" type="file" id="file" accept="image/*" size="14" />
			</label>
		</div>
	</div>
 */
/**
 * Custom file input
 */
.form-input--file {
  position: relative;
  overflow: hidden;
  padding-right: 120px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.form-input--file-input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0; }

.form-input--file-button {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  padding: .44em 1em 0;
  background-color: #d9d9d9; }
  .form-input--file-button:hover {
    background-color: #ccc; }

/**
 * Base form styles
 * =================================
 * Demo: http://trykickoff.com/demos/forms.html#layout-example-labels-above
 * Code: https://gist.github.com/mrmartineau/6712577#file-labels-above-inputs-html
 * Usage:
  	<div class="form-controlGroup">
  		<label for="name" class="form-label">Name</label>
  		<div class="form-controlGroup-inputWrapper">
  			<input type="text" id="name" placeholder="Zander Martineau" class="form-input"/>
  		</div>
  	</div>
 */
fieldset {
  border: none;
  padding: 0 1.0em; }

.form-legend {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }

/**
 * Form inputs and groups
 */
.form-legend + .form-controlGroup {
  -webkit-margin-top-collapse: separate; }

.form-label {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.form-input {
  display: block;
  width: 100%;
  vertical-align: baseline; }
  .form-input:focus {
    outline: 0;
    outline: thin dotted \9; }
  .form-input[type="image"], .form-input[type="checkbox"], .form-input[type="radio"], .form-input[type="range"] {
    width: auto;
    height: auto;
    padding: 0;
    line-height: normal;
    cursor: pointer;
    border-radius: 0;
    box-sizing: border-box; }
  .form-input[type="image"] {
    border: 0; }
  .form-input[type="file"] {
    width: auto;
    height: 28px;
    padding: initial;
    line-height: initial;
    border: initial;
    background-color: initial; }
  .form-input[type="hidden"] {
    display: none; }
  .form-input[type="password"] {
    font-family: sans-serif; }
  .form-input[type="range"] {
    width: 100%; }
  .form-input > .radio:first-child,
  .form-input > .checkbox:first-child {
    padding-top: 5px; }
  .form-input[disabled], .form-input[readonly] {
    cursor: not-allowed; }

.form-input--textarea {
  height: auto;
  min-height: 5em;
  resize: vertical; }

.form-input--textarea--uneditable {
  width: auto;
  height: auto; }

.form-input-file {
  display: inline-block;
  width: 100%;
  margin: 0;
  vertical-align: middle;
  line-height: 1em; }
  .form-input-file:focus {
    outline: none; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  width: auto;
  cursor: pointer;
  -webkit-appearance: button; }

/**
 * Checkboxes:
 * =================================
	<label class="control">
		<input type="checkbox" value="check1">
		This is a checkbox
	</label>
	<label class="control control--inline">
		<input type="checkbox" value="check1">
		This is a checkbox
	</label>

 * Radios:
 * =================================
	<label class="control">
		<input type="radio" name="optionsRadios" id="optionsRadios1" value="option1" checked>
		This is a radio
	</label>
	<label class="control control--inline">
		<input type="radio" name="optionsRadios" id="optionsRadios1" value="option2" checked>
		This is a radio
	</label>
 */
.control {
  display: block;
  line-height: 1; }
  .control + .control {
    margin-top: 14px; }
  .control input {
    vertical-align: top;
    display: inline;
    line-height: 1;
    margin-right: 0.5em; }

.control--inline {
  display: inline-block;
  vertical-align: middle; }
  .control--inline + .control--inline {
    margin-top: 0;
    margin-left: 1.5em; }

/**
 * Form validation messages
 */
.form-controlGroup .form-message {
  display: none; }

/**
 * Links
 * =================================
 */
a {
  transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1); }
  a, a:link {
    color: #288ad6;
    text-decoration: none; }
  a:visited {
    color: #288ad6;
    text-decoration: none; }
  a:hover, a:focus {
    color: #185380; }
  a:active {
    color: #185380; }

/**
 * Buttons
 * =================================
 * - Associated Button Variables
 * - Base button styling
 * – Button Modifiers
 * 	 – btn--primary
 *   – btn--block
 */
/**
 * Define associated Button variables
 */
/**
 * Base button styles – Based on csswizardry.com/beautons
 *
 * 1. Allow us to better style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Stop buttons wrapping and looking broken.
 * 4. Make buttons inherit font styles.
 * 5. Force all elements using beautons to appear clickable.
 * 6. Normalise box model styles.
 * 7. If the button’s text is 1em, and the button is (3 * font-size) tall, then
 *    there is 1em of space above and below that text. We therefore apply 1em
 *    of space to the left and right, as padding, to keep consistent spacing.
 * 8. Fixes odd inner spacing in IE7.
 * 9. Don’t allow buttons to have underlines; it kinda ruins the illusion.
 *10. Prevents from inheriting default anchor styles.
 */
.btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  white-space: nowrap;
  /* [3] */
  font-family: inherit;
  /* [4] */
  font-size: 100%;
  /* [4] */
  cursor: pointer;
  /* [5] */
  border: none;
  /* [6] */
  margin: 0;
  /* [6] */
  padding-top: 0;
  /* [6] */
  padding-bottom: 0;
  /* [6] */
  line-height: 2.5;
  /* [7] */
  height: 2.5em;
  /* [7] */
  padding-right: 1.5em;
  /* [7] */
  padding-left: 1.5em;
  /* [7] */
  overflow: visible;
  /* [8] */
  text-align: center;
  border-radius: 1px;
  user-select: none;
  background-color: #e7e1d9; }
  .btn, .btn:link, .btn:visited {
    color: #fff; }
  .btn:hover, .btn:active, .btn:focus {
    background-color: #d3c8ba; }
  .btn, .btn:hover, .btn:active, .btn:focus, .btn:visited {
    text-decoration: none; }
  .btn:active, .btn:focus {
    outline: none; }
  .btn.disabled, .btn[disabled] {
    cursor: default;
    background-image: none;
    opacity: .5; }

.btn--primary {
  background-color: #e7e1d9;
  border-radius: 5px;
  margin-bottom: 0.25em;
  width: 100%; }
  .btn--primary, .btn--primary:link, .btn--primary:visited {
    color: #7e0408; }
  .btn--primary:hover, .btn--primary:active, .btn--primary:focus {
    background-color: #d3c8ba; }

.btn--block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0; }
  .btn--block + .btn--block {
    margin-top: 10px; }

input[type="submit"].btn--block,
input[type="reset"].btn--block,
input[type="button"].btn--block {
  width: 100%; }

/**
 * Lists
 * =================================
 * - (Un)ordered lists
 * - Unstyled lists
 * - Definition lists
 * - Centred lists
 * - Floated lists
 */
ul {
  list-style-type: disc; }

ol {
  list-style-type: decimal; }

ul, ol {
  padding: 0;
  margin: 0 0 28px 28px; }
  ul ul,
  ul ol, ol ul,
  ol ol {
    margin-left: 56px; }
  ul ul, ol ul {
    list-style-type: circle; }
    ul ul ul, ol ul ul {
      list-style-type: square; }
  ul ol, ol ol {
    list-style-type: upper-roman; }
    ul ol ol, ol ol ol {
      list-style-type: lower-roman; }
  ul ul, ul ol, ol ul, ol ol {
    margin-bottom: 0; }

/**
 * Unstyled lists
 */
.unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-image: none; }

/**
 * Definition lists
 */
dl {
  margin-bottom: 28px; }

dt {
  font-weight: bold; }

dd {
  margin-left: 28px; }

/**
 * Centred lists
 *
 * Usage:
	<ul class="l-list--centred">
		<li></li>
	</ul>
 */
.l-list--centred, .l-list--centered {
  text-align: center; }
  .l-list--centred li, .l-list--centered li {
    display: inline; }

/**
 * Floated lists
 * Usage:
	<ul class="l-list--floated">
		<li></li>
	</ul>
 */
.l-list--floated:after {
  content: "";
  display: table;
  clear: both; }

.l-list--floated li {
  float: left;
  display: inline-block; }

/**
 * Embedded content
 * =================================
 */
img {
  font-style: italic;
  color: #c00;
  border: 0;
  -ms-interpolation-mode: bicubic; }

img,
object,
embed,
video {
  max-width: 100%; }

/**
 * Icons
 * =================================
 * For an example of how to use these, see
 * https://gist.github.com/mrmartineau/314b01b300fa20a5a820a4d6889ffd42
 * http://codepen.io/mrmartineau/pen/amkZBb?editors=1100
 *
 * SVG icon usage:
   <svg title="Whatsapp" class="icon icon--whatsapp">
     <use xlink:href="/assets/dist/img/icons.svg#logo-whatsapp"/>
   </svg>
 */
[class*="icon"] {
  speak: none; }

.icon--large {
  display: block;
  width: 120px;
  height: 120px; }

.icon--xlarge {
  display: block;
  width: 218px;
  height: 218px; }

.icon--small {
  display: inline-block;
  width: 25px;
  height: 25px; }

/**
 * Included Social Media and other icons
 */
.icon--twitter {
  color: #55acee; }

.icon--facebook {
  color: #3b5998; }

.icon--instagram {
  color: #e4405f; }

.icon--pinterest {
  color: #bd081c; }

.icon--youtube {
  color: #cd201f; }

.icon--whatsapp {
  color: #25d366; }

.icon--email {
  color: #fff; }

.icon--mark1 {
  fill: #7e0408;
  color: #f3faf0; }

.icon--phonecall {
  width: 100px;
  height: 100px;
  float: left;
  fill: white;
  color: white;
  padding-left: 1em; }
  @media (min-width: 401px) {
    .icon--phonecall {
      width: 218px;
      height: 218px; } }

.icon--tmw {
  color: #ef7521;
  fill: #8a8b8c; }

/**
 * Skip to main content link
 * =================================
 * <div class="skipToContent">
	 	<a href="#mainContent" class="btn btn--primary">Skip to main content</a>
	 </div>
 */
.skipToContent {
  position: relative;
  text-align: center;
  height: 0; }
  .skipToContent a {
    position: relative;
    top: -100px;
    z-index: 100;
    background-color: #fff;
    transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1); }
    .skipToContent a:focus {
      top: 5px; }

/**
 * Preformatted code
 * =================================
 * Code typography styles for the <code> and <pre> elements
 */
/**
 * Define associated Code variables
 */
code,
pre {
  padding: 0 3px 2px;
  font-family: Menlo, Monaco, "Courier New", monospace;
  font-size: 0.8rem;
  color: #444;
  border-radius: 3px;
  tab-size: 3; }

code {
  padding: 2px 4px;
  color: #444;
  background-color: #ddd; }

pre {
  display: block;
  padding: 14px;
  margin: 0 0 28px;
  color: #444;
  background-color: #ddd;
  border-radius: 4px;
  white-space: pre; }
  pre code {
    padding: 0;
    color: #444;
    background-color: #ddd;
    border: 0; }

.pre--scrollable {
  max-height: 340px;
  overflow-y: scroll; }

/**
 * Tables
 * =================================
 * - Associated Table Variables
 * - Default table Styles
 * – Table modifiers:
 *   - .table--striped
 * – .responsiveTable
 */
/**
 * Define associated Table variables
 */
/**
 * Customizes the `.table` component with basic values, each used across all table variations.
 */
table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 28px;
  border-spacing: 0;
  border: 2px solid #ccc;
  background-color: #f3faf0;
  table-layout: fixed; }
  table th,
  table td {
    padding: 8px;
    line-height: 28px;
    vertical-align: top;
    border: 1px solid #ccc; }
    table th:first-child,
    table td:first-child {
      border-left: 0; }
    table th:last-child,
    table td:last-child {
      border-right: 0; }
  table > thead th,
  table > thead td {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  table > tfoot td {
    border-top: 2px solid #ccc;
    border-bottom: 0; }
  table > thead:first-child > tr:first-child > th,
  table > thead:first-child > tr:first-child > td {
    border-top: 0; }
  table tr:hover td, table tr:hover th {
    background-color: inherit; }
  table > tbody + tbody {
    border-top: 2px solid #ccc; }
  table table,
  table .table {
    background-color: #f3faf0; }

th {
  text-align: left; }

/**
 * Zebra-striping
 * Default zebra-stripe styles (alternating gray and transparent backgrounds)
 */
.table--striped tbody :nth-child(odd) th,
.table--striped tbody :nth-child(odd) td {
  background-color: #f9f9f9 !important; }

/**
 * Responsive tables
 * Wrap your tables in `.responsiveTable` and we'll make them mobile friendly by enabling horizontal scrolling. Only applies <768px. Everything above that will display normally.
 *
 * If you need more responsive tables, consider Tablesaw by the Filament Group:
 * https://github.com/filamentgroup/tablesaw
 */
@media (max-width: 749px) {
  .responsiveTable {
    width: 100%;
    margin-bottom: 21px;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ccc;
    -webkit-overflow-scrolling: touch; }
    .responsiveTable > table {
      margin-bottom: 0;
      border: 0; }
      .responsiveTable > table > thead th,
      .responsiveTable > table > thead td,
      .responsiveTable > table > tbody th,
      .responsiveTable > table > tbody td,
      .responsiveTable > table > tfoot th,
      .responsiveTable > table > tfoot td {
        white-space: nowrap; }
        .responsiveTable > table > thead th:first-child,
        .responsiveTable > table > thead td:first-child,
        .responsiveTable > table > tbody th:first-child,
        .responsiveTable > table > tbody td:first-child,
        .responsiveTable > table > tfoot th:first-child,
        .responsiveTable > table > tfoot td:first-child {
          border-left: 0; }
        .responsiveTable > table > thead th:last-child,
        .responsiveTable > table > thead td:last-child,
        .responsiveTable > table > tbody th:last-child,
        .responsiveTable > table > tbody td:last-child,
        .responsiveTable > table > tfoot th:last-child,
        .responsiveTable > table > tfoot td:last-child {
          border-right: 0; }
      .responsiveTable > table > tbody > th,
      .responsiveTable > table > tbody > td,
      .responsiveTable > table > tfoot tr:last-child > th,
      .responsiveTable > table > tfoot tr:last-child > td {
        border-bottom: 0; } }

/**
 * Partials
 */
/**
 * Masthead
 * =================================
 */
.icon--mark1, h1, h2 {
  fill: #fff; }

h1, h2, h3, .tel {
  color: #fff; }

h2 {
  margin: 1.0em 0 0.1em 0; }

/**
 * Footer
 * =================================
 */
/**
 * Browser upgrade message
 * =================================
 */
.browserupgrade {
  margin: .2em 0;
  background: #ccc;
  color: #000;
  padding: .2em 0; }

/**
 * Views
 */
/**
 * Home
 * =================================
 */
body {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAaCAYAAADWm14/AAAAWUlEQVRIie2VMQ4AIAgDq2Ew8bm+nVkXZzBG06VdGGjJpQtlWJsgynqw9D0jT6STfL28/UwCEIAABEAHMM89OPHc5vkNAH+fTZanNyAAAQhAAHQAA/4+m0wLM+AKjuRkdoEAAAAASUVORK5CYII="); }
  @media (min-width: 401px) {
    body {
      background: url("/assets/dist/img/m1b-background.jpg") no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover; } }

.tout {
  background-color: #a07348;
  width: 100%;
  height: 100%; }
  @media (min-width: 401px) {
    .tout {
      float: left;
      margin: 0 3px;
      min-height: 21em; } }

h1 {
  margin-bottom: 0; }

h2, .phone-icon-bb, .tel {
  display: block; }
  @media (min-width: 401px) {
    h2, .phone-icon-bb, .tel {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1em; } }

h2 {
  margin-left: 0.15em; }

.alt-copy::before {
  content: "Tap to "; }
  @media (min-width: 401px) {
    .alt-copy::before {
      content: ""; } }

.tel {
  margin: 1em;
  padding-left: 4em; }
  @media (min-width: 401px) {
    .tel {
      margin-bottom: 1em;
      padding: 0; } }

@keyframes ring {
  40% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.keyframe2 {
  animation: ring 2s infinite; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a5734c; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #a5734c; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: #a5734c; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #a5734c; }

/**
 * Global styles
 */
/**
 * Global app/site styles
 * =================================
 */
/**
 * Default layout container
 */
.l-container {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

/**
 * Helper/utility classes
 */
/**
 * Helper classes
 * =================================
 * Non-semantic helper classes
 */
/**
 * Clearfix
 * http://www.cssmojo.com/latest_new_clearfix_so_far/
 * See mixins/_utility.scss for a mixin version of this
 */
.clearfix::after {
  content: "";
  display: table;
  clear: both; }

.ir {
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  direction: ltr;
  display: block;
  overflow: hidden;
  text-align: left;
  text-indent: -999em; }
  .ir br {
    display: none; }

.is-hidden {
  display: none !important;
  visibility: hidden !important; }

.is-shown {
  display: block; }

.is-visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .is-visuallyHidden.focusable:active, .is-visuallyHidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto; }

.is-invisible {
  visibility: hidden; }

.l-floatLeft {
  float: left; }

.l-floatRight {
  float: right; }

.l-clear {
  clear: both;
  float: none; }

.l-mb0 {
  margin-bottom: 0 !important; }

.l-mt0 {
  margin-top: 0 !important; }

* {
  -webkit-tap-highlight-color: transparent !important; }

/* A hack for HTML5 contenteditable attribute on mobile */
textarea[contenteditable] {
  -webkit-appearance: none; }

.promote-layer {
  /*
	This may promote the layer to a composited
	layer.

	Replace with will-change when available

	#perfmatters
	*/
  backface-visibility: hidden; }

.transition {
  transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1); }

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  touch-action: manipulation; }

/**
 * Print styles
 */
/**
 * Print styles
 * =================================
 */
@media print {
  *,
  *:before,
  *:after,
  *:first-letter {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important; }
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  /*
	 * Don't show links for images, or javascript/internal links
	 */
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group;
    /* h5bp.com/t */ }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: .5cm; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; } }
