/**
 * Masthead
 * =================================
 */
.masthead {}

.icon--mark1, h1, h2 {
  fill: $bright;
}

h1, h2, h3, .tel {
  color: $bright;
}

h2 {
  margin: 1.0em 0 0.1em 0;
}