/**
 * Custom select element
 * =================================
 * Firefox 39+, IE10+
 *
 *	<div class="form-controlGroup">
			<label for="cs-choice" class="form-label">Custom select</label>
			<div class="form-controlGroup-inputWrapper form-controlGroup-inputWrapper--select">
				<select id="cs-choice" name="cs-choice" class="form-input form-input--select">
					<option value="--">Select title</option>
					<option value="Mr">Mr</option>
					<option value="Mrs">Mrs</option>
				</select>
			</div>
	</div>
 */

.form-controlGroup-inputWrapper--select {
	position: relative;
	display: block;
	padding: 0;

	/* Custom arrow sits on top of the select - could be an image, SVG, icon font, etc. or the arrow could just baked into the bg image on the select */
	&:after {
		color: $color-primary;
		content: "\25BC";
		font-size: .7em;
		padding: 0;
		position: absolute;
		right: 1.2em;
		top: 1.2em;
		z-index: 2;

		/* These hacks make the select behind the arrow clickable in some browsers */
		-ms-pointer-events: none;
		pointer-events: none;
		background: url(#);
	}

	// Hide custom arrow on IE <=11
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		&:after {
			display: none;
		}
	}

	.form-input--select {
		appearance: none;

		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			// Removes the odd blue bg color behind the text in IE 10/11 and sets the text to match the focus style text
			&:focus::-ms-value {
				background: transparent;
				color: #222;
			}
		}
	}
}

option {
	font-weight: normal;
}
