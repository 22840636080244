/**
 * Icons
 * =================================
 * For an example of how to use these, see
 * https://gist.github.com/mrmartineau/314b01b300fa20a5a820a4d6889ffd42
 * http://codepen.io/mrmartineau/pen/amkZBb?editors=1100
 *
 * SVG icon usage:
   <svg title="Whatsapp" class="icon icon--whatsapp">
     <use xlink:href="/assets/dist/img/icons.svg#logo-whatsapp"/>
   </svg>
 */
[class*="icon"] {
	speak: none;
}

.icon--large {
	display: block;
	width: 120px;
	height: 120px;
}

.icon--xlarge {
  display: block;
  width: 218px;
  height: 218px;
}

.icon--small {
	display: inline-block;
	width: 25px;
	height: 25px;
}

/**
 * Included Social Media and other icons
 */
.icon--twitter { color: $color-twitter; }
.icon--facebook { color: $color-facebook; }
.icon--instagram { color: $color-instagram; }
.icon--pinterest { color: $color-pinterest; }
.icon--youtube { color: $color-youtube; }
.icon--whatsapp { color: $color-whatsapp; }
.icon--email { color: $bright; }
.icon--mark1 {
	fill: $color-m1b-red;
	color: $color-m1b-offwhite;
}

.icon--phonecall {
  width: 100px;
  height: 100px;
  float: left;
  fill: lighten($color-m1b-offwhite, 70%);
  color: lighten($color-m1b-offwhite, 70%);
  padding-left: 1em;

  @include media('>narrow') {
    width: 218px;
    height: 218px;
  }
}
.icon--tmw {
	color: #ef7521;
	fill: #8a8b8c;
}
