/**
 * Helper classes
 * =================================
 * Non-semantic helper classes
 */

/**
 * Clearfix
 * http://www.cssmojo.com/latest_new_clearfix_so_far/
 * See mixins/_utility.scss for a mixin version of this
 */
.clearfix::after {
	content: "";
	display: table;
	clear: both;
}


// For background image replacement
.ir {
	background-color: transparent;
	background-repeat: no-repeat;
	border: 0;
	direction: ltr;
	display: block;
	overflow: hidden;
	text-align: left;
	text-indent: -999em;

	br {
		display: none;
	}
}


// Hide from both screenreaders and browsers: h5bp.com/u
.is-hidden {
	display: none !important;
	visibility: hidden !important;
}

.is-shown { display: block; }

// Hide only visually, but have it available for screenreaders: h5bp.com/v
.is-visuallyHidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;

	// Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p
	&.focusable:active,
	&.focusable:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
	}
}

// Hide visually and from screenreaders, but maintain layout
.is-invisible { visibility: hidden; }

.l-floatLeft {
	float: left;
}

.l-floatRight {
	float: right;
}

.l-clear {
	clear: both;
	float: none;
}

.l-mb0 { margin-bottom: 0 !important; }
.l-mt0 { margin-top: 0 !important; }


* {
	-webkit-tap-highlight-color: transparent !important;
}

/* A hack for HTML5 contenteditable attribute on mobile */
textarea[contenteditable] {
	-webkit-appearance: none;
}

.promote-layer {
	/*
	This may promote the layer to a composited
	layer.

	Replace with will-change when available

	#perfmatters
	*/
	backface-visibility: hidden;
}

// Add the default transition to any element
.transition {
	transition: $default-transition;
}

// Faster taps on mobile devices
// See https://adactio.com/journal/10019 for more info
a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
	touch-action: manipulation;
}
