/**
 * Home
 * =================================
 */
body {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAaCAYAAADWm14/AAAAWUlEQVRIie2VMQ4AIAgDq2Ew8bm+nVkXZzBG06VdGGjJpQtlWJsgynqw9D0jT6STfL28/UwCEIAABEAHMM89OPHc5vkNAH+fTZanNyAAAQhAAHQAA/4+m0wLM+AKjuRkdoEAAAAASUVORK5CYII=');

  @include media('>narrow') {
    background: url('#{$img-path}/m1b-background.jpg') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.tout {
  background-color: $color-primary;
  width: 100%;
  height: 100%;

  @include media('>narrow') {
    float: left;
    margin: 0 3px;
    min-height: 21em;
  }

}

h1 {
  margin-bottom: 0;

  @include media('>narrow') {

  }
}

h2, .phone-icon-bb, .tel {
  
  display: block;

  @include media('>narrow') {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
  }
}

h2 {
  margin-left: 0.15em;
}

.alt-copy::before {
    content: "Tap to ";

  @include media('>narrow') {
    content: "";  
  }
}

.tel {
  margin: 1em;
  padding-left: 4em;

  @include media('>narrow') {
    margin-bottom: 1em;
    padding: 0;
  }
}

@keyframes ring {
  40%  { opacity: 0; }
  100% { opacity: 1; }
}  

.keyframe2 {
  animation: ring 2s infinite;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $brown;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $brown;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $brown;
}
:-moz-placeholder { /* Firefox 18- */
  color: $brown;
}