/**
 * Preformatted code
 * =================================
 * Code typography styles for the <code> and <pre> elements
 */

/**
 * Define associated Code variables
 */
$code-textColor               : #444;
$code-bgColor                 : #ddd;
$pre-textColor                : #444;
$pre-bgColor                  : #ddd;

// Inline and block code styles
code,
pre {
	padding: 0 3px 2px;
	font-family: $font-family-mono;
	@include ko-font-size(small);
	color: $code-textColor;
	border-radius: 3px;
	tab-size: 3;
}

// Inline code
code {
	padding: 2px 4px;
	color: $code-textColor;
	background-color: $code-bgColor;
}

// Blocks of code
pre {
	display: block;
	padding: $baseline / 2;
	margin: 0 0 $baseline;
	// line-height: $leading-base;
	color: $pre-textColor;
	background-color: $pre-bgColor;
	border-radius: 4px;
	white-space: pre;
	// white-space: pre-wrap;
	// word-break: break-all;
	// word-wrap: break-word;

	// Account for some code outputs that place code tags in pre tags
	code {
		padding: 0;
		color: $pre-textColor;
		background-color: $pre-bgColor;
		border: 0;
	}
}

// Enable scrollable blocks of code
.pre--scrollable {
	max-height: 340px;
	overflow-y: scroll;
}
